import { mapActions, mapGetters } from "vuex";
import { TABLE_HEADERS, SEARCH_TEXT_FIELDS, SEARCH_DATE_FIELDS } from "@/constants/statisticsConstants";
import ExcelExport from "@/components/ExcelExport";
import { getDateFormat } from "@/utils/getDateFormat.util"
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime';
import adminCheckerMixin from '@/mixins/adminChecker.mixin'

dayjs.extend(relativeTime)

export default {
  components: {
    ExcelExport,
  },

  mixins: [adminCheckerMixin],

  data() {
    return {
      menu: false,
      modal: false,
      dates: [],
      howManyDays: Number,
      searchbar: '',
      loading: true,
      results: [],
      headers: TABLE_HEADERS,
      dateRangeColumn: null,
      searchColumn: null,
    };
  },

  watch: {
    dateRangeColumn() {
      if(this.dates?.length) this.filterUsers();
    },

    searchColumn() {
      this.filterUsers();
    },

    dates(newVal) {
      if(newVal === null) this.dates = []
    }
  },

  computed: {
    ...mapGetters({
      statistics: "statisticsLogic/getStatistics",
    }),
    searchTextFields() {
      return SEARCH_TEXT_FIELDS
    },

    searchDateFields() {
      return SEARCH_DATE_FIELDS
    },

    resultsLength() {
      return this.results?.length || 0
    },

    statsLength() {
      return this.statistics?.length || 0
    }    
  },

  methods: {
    ...mapActions({
      getUserStatistics: 'statisticsLogic/getUserStatistics',
      getUser: 'profile/establishUserAuth'
    }),

    ...mapActions("profile", ["establishUserAuth"]),

    ...mapActions('users', ['getDbProfiles']),

    sortDate(date) {
      if(!date || new Date(date).toString() === 'Invalid Date') return date
      return getDateFormat(date, 'MM/DD/YY')
    },

    onlineLast(date) {
      return dayjs(date).fromNow()
    },

    resetFilters(type) {
      if(type === 'daterange') this.dates = [];
      this.results = this.statistics;
      this.filterUsers();
    },

    getAge(birthDate) {
      return Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10) || '';
    },

    filterUsers() {
      let found = [];
      this.statistics.forEach(user => {
        if(this.dates?.length) {
          const from = new Date(this.dates[0]);
          const to = new Date(this.dates[1]);

          if(user[this.dateRangeColumn]) {
            if(new Date(user[this.dateRangeColumn]) >= from && new Date(user[this.dateRangeColumn]) <= to) {
              found.push(user);
            }
          }
        }
      });

      if(this.searchbar?.length && this.searchColumn) {
        if(!this.dates?.length) found = this.statistics;
        found = found.filter(user => {
          if(user[this.searchColumn]?.toLowerCase()?.indexOf(this.searchbar.toLowerCase()) > -1) return user;
        });
      }
      else {
        if(!this.dates?.length) found = this.statistics;
      }
      this.modal = false;
      this.results = found
    },
  },

  async created() {
    await this.getDbProfiles()
    await this.getUserStatistics()
    this.results = this.statistics
    this.loading = false;
  },
}