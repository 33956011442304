<template>
  <v-container fluid class="full-height">
    <v-row>
      <v-col cols="12">
        <v-toolbar flat>
          <v-toolbar-title>
            <h3>
              Statistics
              <span style="font-size: 16px; font-weight: normal">
                for
                <strong>{{ resultsLength }}</strong>
                out of
                <strong>{{ statsLength }}</strong>
                total users
              </span>
            </h3>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <excel-export class="pa-2 cursor-pointer" :json-data="results"/>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-text-field
            v-model="searchbar"
            @input="filterUsers"
            label="Search"
            outlined
            rounded
            prepend-icon="search"
            clearable
            @click:clear='resetFilters'
            persistent-hint
        />
      </v-col>
      <v-col cols="2">
        <v-select 
          v-if="searchTextFields" 
          outlined
          rounded
          :items="searchTextFields"
          v-model="searchColumn"
          item-text="label"
          item-value="key"
          label="Select text column to filter by" />
      </v-col>
      <v-col cols="1" style="padding-top: 30px;">
        - or -
      </v-col>
      <v-col cols="3">
        <v-dialog
            ref="dialog"
            v-model="modal"
            persistent
            width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dates"
                label="Date range"
                prepend-icon="mdi-calendar"
                persistent-hint
                readonly
                rounded
                v-bind="attrs"
                v-on="on"
                clearable
                outlined
                @click:clear='resetFilters("daterange")'
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="dates"
              scrollable
              range
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              Cancel
            </v-btn>
            <v-btn :disabled="!dates[1]" text color="primary" @click="filterUsers">
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="2">
        <v-select 
          v-if="searchDateFields" 
          outlined
          rounded
          :items="searchDateFields"
          item-text="label"
          item-value="key"
          v-model="dateRangeColumn"
          label="Select date column to filter by" />
      </v-col>

      <v-col cols="12">
        <v-data-table
            fixed-header
            height="500px"
            :headers="headers"
            :items="results"
            loading-text="Loading ... Please wait"
            :items-per-page="25"
            :footer-props="{
              'items-per-page-options': [25,50,100,-1]
            }"
            sort-by="created_at"
            :sort-desc="true"
        >
          <template v-slot:item.date_of_birth="{ item }">
            {{ getAge(item.date_of_birth) }}
          </template>

          <template v-slot:item.created_at="{ item }">
            {{ sortDate(item.created_at) }}
          </template>

          <template v-slot:item.completed="{ item }">
            {{ sortDate(item.completed) }}
          </template>

          <template v-slot:item.signed="{ item }">
            {{ sortDate(item.signed) }}
          </template>          

          <template v-slot:item.last_sign_in="{ item }">
            <div class="d-flex align-baseline">
              <span>
                {{ sortDate(item.last_sign_in) }}
              </span>
              <v-badge
                  v-if="item.last_sign_in"
                  :color="Math.abs(item.totalDays) > 1 ? 'error' : 'primary'"
                  class="ms-2"
                  :title="onlineLast(item.last_sign_in)"
                  inline
                  dot
              ></v-badge>
            </div>
          </template>

          <template v-slot:item.payment_date_1="{ item }">
            {{ sortDate(item?.payment_date_1) }}
          </template>

          <template v-slot:item.payment_date_2="{ item }">
            {{ sortDate(item?.payment_date_2) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import statisticsMixin from '../mixins/views/statistics.mixin';

export default {
  name: "Statistics",

  mixins: [statisticsMixin]
};
</script>

<style lang="scss">
.text-start {
  white-space: nowrap;
}
</style>