import dateSort from '@/utils/dateSort.util'

export const LIMIT_ALERTS = {
  WARNING_ALERT: 200,
  RED_ALERT: 400,
}

export const SEARCH_TEXT_FIELDS = [
  {
    label: 'Last Name',
    key: 'last_name'
  },
  {
    label: 'First Name',
    key: 'first_name'
  },
  {
    label: 'Email',
    key: 'email'
  }
]

export const SEARCH_DATE_FIELDS = [
  {
    label: 'Last Login',
    key: 'last_sign_in'
  },
  {
    label: 'Created At',
    key: 'created_at'
  },
  {
    label: 'Completed',
    key: 'completed'
  },
  {
    label: 'Paywall 1 Date',
    key: 'payment_date_1'
  },
  {
    label: 'Paywall 2 Date',
    key: 'payment_date_2'
  },
]

export const TABLE_HEADERS = [
  {
    text: 'Last Name',
    value: 'last_name',
    sortable: true,
    width: '100%'
  },
  {
    text: 'First Name',
    value: 'first_name',
    sortable: true,
    width: '100%'
  },
  {
    text: 'Verified',
    value: 'verified',
    sortable: true,
    width: '100%'
  },
  {
    text: 'Email',
    value: 'email',
    sortable: true,
  },
  {
    text: 'Spouse Email',
    value: 'spouse_email',
    sortable: true,
  },  
  {
    text: 'Gender',
    value: 'gender',
    sortable: false,
  },
  {
    text: 'Age',
    value: 'date_of_birth',
    sortable: false,
  },
  {
    text: 'City',
    value: 'address_city',
    sortable: true,
  },
  {
    text: 'State',
    value: 'address_state',
    sortable: true,
  },
  {
    text: 'Zip',
    value: 'address_zip_code',
    sortable: false,
    width: 'fit-content'
  },
  {
    text: 'Type',
    value: 'type',
    sortable: true,
    width: '100%'
  },
  {
    text: 'Step',
    value: 'step',
    sortable: true,
  },
  {
    text: 'Collaborate',
    value: 'collaborate',
    sortable: true,
  },  
  {
    text: 'Created at',
    text: 'Created',
    value: 'created_at',
    sortable: true,
    sort: dateSort
  },
  {
    text: 'Last Login',
    value: 'last_sign_in',
    sortable: true,
    sort: dateSort
  },
  {
    text: 'Completed',
    value: 'completed',
    sortable: true,
    sort: dateSort
  },
  {
    text: 'Signed',
    value: 'signed',
    sortable: true,
    sort: dateSort
  },
  {
    text: 'Workflow',
    value: 'workflow',
    sortable: true,
  },
  {
    text: 'Paywall 1 Date',
    value: 'payment_date_1',
    sortable: true,
    sort: dateSort,
    width: '100%'
  },
  {
    text: 'Paywall 1 Amt',
    value: 'payments.amount_1',
    align: 'center',
    sortable: true,
    width: '100%'
  },
  {
    text: 'Paywall 2 Date',
    value: 'payment_date_2',
    sortable: true,
    sort: dateSort,
    width: '100%'
  },
  {
    text: 'Paywall 2 Amt',
    value: 'payments.amount_2',
    align: 'center',
    sortable: true,
    width: '100%'
  },
  {
    text: 'Coupon code',
    value: 'payments.couponCode',
    align: 'center',
    sortable: true,
  }
]
