<template>
  <div>
    <json-excel :name="'Statistics of ' + new Date().toDateString()" :data="finalData[0]">
      <v-btn
        color="blue-grey"
        class="ma-2 white--text"
        @click="formDataForExcel"
        :disabled="!this.jsonData.length"
      >
        Download statistics
        <v-icon right dark>
          mdi-cloud-download
        </v-icon>
      </v-btn>
    </json-excel>
  </div>
</template>
<script>
import JsonExcel from "vue-json-excel";

export default {
  components: {
    JsonExcel,
  },

  props: {
    jsonData: {
      required: true,
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      finalData: [],
    }
  },

  methods: {
    formDataForExcel() {
      if(!this.jsonData.length) return [];
      let counter = 1;

      const finalColumns = this.jsonData.map(e => {
        return {
          '#No': counter++,
          'User / Admin': e.role,
          'User first name': e.first_name,
          'User last name': e.last_name,
          'Verified:': e.verified,
          'Phone number': e.phone_number,
          'Email': e.email,
          'Spouse Email': e.spouse_email,
          'Gender': e.gender,
          'Date of birth': e.date_of_birth,
          'City': e.address_city,
          'State': e.address_state,
          'Zip code': e.address_zip_code,
          'Type': e.is_petitioner ? 'Petitioner' : 'Respondent',
          'Step': e.step,
          'Collaborate': e.collaborate,
          'Created': new Date(e.created_at).toDateString(),
          'Last login': new Date(e.last_sign_in).toDateString(),
          'Permission to contact': e.permission_to_contact && e.permission_to_contact ? 'Yes' : 'No',
          'Completed': e.completed ? new Date(e.completed).toDateString() : '',
          'Signed': e.signed ? new Date(e.signed).toDateString() : '',
          'Workflow': e.workflow,
          'Paywall 1 Date': new Date(e.payment_date_1).toDateString(),
          'Paywall 1 Amt': e.payments && e.payments.amount_1 && e.payments.amount_1,
          'Paywall 2 Date': new Date(e.payment_date_2).toDateString(),
          'Paywall 2 Amt': e.payments && e.payments.amount_2 && e.payments.amount_2,
          'Coupon code': e.payments && e.payments.couponCode,
          'Coupon code used date': e.payments && new Date(e.payments.dateStamp).toDateString()
        }
      })

      this.finalData.push(finalColumns)
    },
  }
};
</script>
